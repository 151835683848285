<template>
  <b-modal
    :id="id"
    size="sm"
    :title="title"
    cancel-variant="outline-primary"
    centered
    hide-footer
    @hidden="resetData"
  >
    <div class="modal-report">
      <validation-observer ref="rule">
        <b-form class="content-form">

          <b-form-group>
            <label for="InputHelp">Tên công văn<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="count"
              rules="required"
              :custom-messages="{required: 'Tên công văn là bắt buộc'}"
            >
              <b-form-input
                v-model="dataInput.name"
                :disabled="type === 'edit' ? true : false"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <b-form-checkbox
              v-model="dataInput.isAllBussiness"
              :disabled="type === 'edit' ? true : false"
              @input="checkedBussiness"
            >
              <label>Tất cả doanh nghiệp</label>
            </b-form-checkbox>
          </b-form-group>
          <b-form-group>
            <label for="basicInput">Loại doanh nghiệp</label>
            <v-select
              :reduce="item => item .id"
              label="name"
              :disabled="dataInput.isAllBussiness || type === 'edit' ? true : false"
              :options="dataTypeOfBussiness"
              :placeholder="'Lựa chọn loại doanh nghiệp'"
              @input="handleFilterBussiness"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
          <b-form-group>
            <label for="basicInput">Doanh nghiệp<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              :rules="dataInput.isAllBussiness ?'' : 'required'"
              :custom-messages="{required: 'Chọn doanh nghiệp là bắt buộc'}"
            >
              <v-select
                v-model="dataInput.listBusinessIds"
                :reduce="item => item .id"
                label="name"
                multiple
                :close-on-select="false"
                :disabled="dataInput.isAllBussiness || type === 'edit' ? true : false"
                :options="dataCombobox"
                :class="{ 'is-invalid': errors.length > 0 }"
                :placeholder="'Lựa chọn doanh nghiệp'"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label-for="date"
          >
            <label for="basicInput">File đính kèm<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              rules="required"
              :custom-messages="{required: 'File đính kèm là bắt buộc'}"
            >

              <div class="d-flex">
                <b-form-input
                  v-model="dataInput.fileName"
                  :disabled="true"
                  :state="errors.length > 0 ? false:null"
                />

                <input
                  ref="upload-file"
                  type="file"
                  class="d-none"
                  accept="application/msword, application/pdf"
                  :disabled="type === 'edit' ? true : false"
                  @change="importFileExcel"
                />
                <b-button
                  :variant="errors.length > 0 ? 'outline-danger':'outline-primary'"
                  class="btn-button-file ml-1"
                  @click="uploadFile"
                >
                  <feather-icon
                    icon="UploadIcon"
                    :class="errors.length > 0 ? 'text-danger':'text-primary'"
                  />
                </b-button>

              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <label for="InputHelp">Nội dung</label>
            <b-form-textarea
              v-model="dataInput.content"
              class="text-area-scroll"
              :disabled="type === 'edit' ? true : false"
              rows="3"
              no-resize
            />
          </b-form-group>
        </b-form>
      </validation-observer>
      <footer
        id="footer-modal-hidden"
      >
        <button
          type="button"
          class="btn btn-outline-primary button-position"
          @click="$emit('hiddeModal')"
        >
          Hủy bỏ
        </button>
        <button
          type="button"
          class="btn btn-primary button-position"
          @click="handleCreate($event)"
        >
          Lưu
        </button>
      </footer>

      <b-overlay
        id="overlay"
        :show="isLoading"
        no-wrap
      >
      </b-overlay>
    </div>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BModal,
  BFormInput,
  BForm,
  BFormTextarea,
  BButton,
  BFormCheckbox,
  BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

import VSelect from 'vue-select'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormTextarea,
    BButton,
    DateTimePicker,
    VSelect,
    BFormCheckbox,
    BOverlay,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    dataDetail: {
      type: Object,
      default: _ => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      dataCombobox: [],
      urlQuery: {
        bussinessClass: '',
      },
      dataInput: {
        name: '',
        content: '',
        isAllBussiness: false,
        listBusinessIds: null,
        fileName: '',
        isSecure: false,
        modelFormData: {
          files: '',
          isSecure: false,
        },
      },
      dataTypeOfBussiness: [
        {
          id: 'Bussiness',
          name: 'Doanh nghiệp',
        },
        {
          id: 'Accreditation',
          name: 'Tổ chức kiểm định',
        },
        {
          id: 'Training',
          name: 'Tổ chức huấn luyện',
        },
        {
          id: 'Health',
          name: 'Trung tâm y tế',
        },
        {
          id: 'JobCenter',
          name: 'Trung tâm giới thiệu việc làm',
        },
        {
          id: 'StateAgencies',
          name: 'Quận/Huyện, Phường/Xã',
        },
      ],
    }
  },
  watch: {
    dataDetail(val) {
      if (val) {
        this.dataInput = {
          name: this.dataDetail.name,
          content: this.dataDetail.content,
          isAllBussiness: this.dataDetail.isAllBussiness,
          listBusinessIds: this.dataDetail.listBusinessIds,
          fileName: this.dataDetail.fileName,
        }
      }
    },
  },
  created() {
    this.fecthCombobox()
  },
  methods: {
    handleCreate(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('accept', this.dataInput)
        }
      })
    },
    async fecthCombobox(urlQuery) {
      const { data } = await axiosApiInstance.get(ConstantsApi.GET_ALL_BUSSINESS, { params: urlQuery })
      this.dataCombobox = data?.data
    },
    resetData() {
      this.dataInput = {
        name: '',
        content: '',
        isAllBussiness: false,
        listBusinessIds: [],
        fileName: '',
        isSecure: false,
      }
    },
    checkedBussiness() {
      this.dataInput.listBusinessIds = null
    },
    uploadFile() {
      this.$refs['upload-file'].click()
    },
    importFileExcel(e) {
      this.dataInput.fileName = this.$refs['upload-file'].files[0].name
      const tmpFiles = e.target.files || e.dataTransfer.files
      if (!tmpFiles.length) { return }
      const file = tmpFiles[0]
      this.dataInput.modelFormData = {
        files: file,
        isSecure: false,
      }
    },

    handleFilterBussiness(val) {
      this.urlQuery.bussinessClass = val
      this.fecthCombobox(this.urlQuery)
    },
  },

}
</script>

<style lang="scss">
.modal-report {
  .action {
    display: flex;
    justify-content: end;
  }
  .vs__selected-options {
    overflow-y: overlay;
    max-height: 100px;
  }
  #footer-modal-hidden {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 1.4rem 0;
  }
  .button-position {
    margin-left: 0.75rem;
    margin: 0.25rem;
  }
  .text-area-scroll {
    overflow-y: overlay;
    max-height: 300px;
  }
}
</style>
