var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":_vm.id,"size":"sm","title":_vm.title,"cancel-variant":"outline-primary","centered":"","hide-footer":""},on:{"hidden":_vm.resetData}},[_c('div',{staticClass:"modal-report"},[_c('validation-observer',{ref:"rule"},[_c('b-form',{staticClass:"content-form"},[_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v("Tên công văn"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"count","rules":"required","custom-messages":{required: 'Tên công văn là bắt buộc'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.type === 'edit' ? true : false,"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.name),callback:function ($$v) {_vm.$set(_vm.dataInput, "name", $$v)},expression:"dataInput.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('b-form-checkbox',{attrs:{"disabled":_vm.type === 'edit' ? true : false},on:{"input":_vm.checkedBussiness},model:{value:(_vm.dataInput.isAllBussiness),callback:function ($$v) {_vm.$set(_vm.dataInput, "isAllBussiness", $$v)},expression:"dataInput.isAllBussiness"}},[_c('label',[_vm._v("Tất cả doanh nghiệp")])])],1),_c('b-form-group',[_c('label',{attrs:{"for":"basicInput"}},[_vm._v("Loại doanh nghiệp")]),_c('v-select',{attrs:{"reduce":function (item) { return item .id; },"label":"name","disabled":_vm.dataInput.isAllBussiness || _vm.type === 'edit' ? true : false,"options":_vm.dataTypeOfBussiness,"placeholder":'Lựa chọn loại doanh nghiệp'},on:{"input":_vm.handleFilterBussiness},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}])})],1),_c('b-form-group',[_c('label',{attrs:{"for":"basicInput"}},[_vm._v("Doanh nghiệp"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"rules":_vm.dataInput.isAllBussiness ?'' : 'required',"custom-messages":{required: 'Chọn doanh nghiệp là bắt buộc'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:{ 'is-invalid': errors.length > 0 },attrs:{"reduce":function (item) { return item .id; },"label":"name","multiple":"","close-on-select":false,"disabled":_vm.dataInput.isAllBussiness || _vm.type === 'edit' ? true : false,"options":_vm.dataCombobox,"placeholder":'Lựa chọn doanh nghiệp'},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(searching)?[_vm._v(" Không có kết quả. ")]:_c('em',[_vm._v("Không có dữ liệu")])]}}],null,true),model:{value:(_vm.dataInput.listBusinessIds),callback:function ($$v) {_vm.$set(_vm.dataInput, "listBusinessIds", $$v)},expression:"dataInput.listBusinessIds"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"date"}},[_c('label',{attrs:{"for":"basicInput"}},[_vm._v("File đính kèm"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"rules":"required","custom-messages":{required: 'File đính kèm là bắt buộc'}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex"},[_c('b-form-input',{attrs:{"disabled":true,"state":errors.length > 0 ? false:null},model:{value:(_vm.dataInput.fileName),callback:function ($$v) {_vm.$set(_vm.dataInput, "fileName", $$v)},expression:"dataInput.fileName"}}),_c('input',{ref:"upload-file",staticClass:"d-none",attrs:{"type":"file","accept":"application/msword, application/pdf","disabled":_vm.type === 'edit' ? true : false},on:{"change":_vm.importFileExcel}}),_c('b-button',{staticClass:"btn-button-file ml-1",attrs:{"variant":errors.length > 0 ? 'outline-danger':'outline-primary'},on:{"click":_vm.uploadFile}},[_c('feather-icon',{class:errors.length > 0 ? 'text-danger':'text-primary',attrs:{"icon":"UploadIcon"}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v("Nội dung")]),_c('b-form-textarea',{staticClass:"text-area-scroll",attrs:{"disabled":_vm.type === 'edit' ? true : false,"rows":"3","no-resize":""},model:{value:(_vm.dataInput.content),callback:function ($$v) {_vm.$set(_vm.dataInput, "content", $$v)},expression:"dataInput.content"}})],1)],1)],1),_c('footer',{attrs:{"id":"footer-modal-hidden"}},[_c('button',{staticClass:"btn btn-outline-primary button-position",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('hiddeModal')}}},[_vm._v(" Hủy bỏ ")]),_c('button',{staticClass:"btn btn-primary button-position",attrs:{"type":"button"},on:{"click":function($event){return _vm.handleCreate($event)}}},[_vm._v(" Lưu ")])]),_c('b-overlay',{attrs:{"id":"overlay","show":_vm.isLoading,"no-wrap":""}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }